import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './modules/home/pages/login/login.component';
import { AuthGuard } from './guard/auth.guard';

const routes: Routes = [
  {
    path: 'account',
    loadChildren: () =>
      import('./modules/users/users.module').then((m) => m.UsersModule),
    data: { title: 'Account' },
    canActivate: [AuthGuard],
  },
  {
    path: 'questions',
    loadChildren: () =>
      import('./modules/questions-builder/questions-builder.module').then(
        (m) => m.QuestionsBuilderModule
      ),
    data: { title: 'Questions' },
    canActivate: [AuthGuard],
  },
  {
    path: 'assessments',
    loadChildren: () =>
      import('./modules/assessments-builder/assessments-builder.module').then(
        (m) => m.AssessmentsBuilderModule
      ),
    data: { title: 'Assessments' },
    canActivate: [AuthGuard],
  },
  {
    path: 'invitations',
    loadChildren: () =>
      import('./modules/invitations-builder/invitations-builder.module').then(
        (m) => m.InvitationsBuilderModule
      ),
    data: { tite: 'Invitations' },
    canActivate: [AuthGuard],
  },
  {
    path: 'take-assessment',
    loadChildren: () =>
      import('./modules/assessments-taker/assessments-taker.module').then(
        (m) => m.AssessmentsTakerModule
      ),
    data: { title: 'Take Assessment' },
  },
  {
    path: 'reviews',
    loadChildren: () =>
      import('./modules/reviews-manager/reviews-manager.module').then(
        (m) => m.ReviewsManagerModule
      ),
    data: { title: 'Reviews' },
    canActivate: [AuthGuard],
  },
  {
    path: 'users',
    loadChildren: () =>
      import('./modules/users-manager/users-manager.module').then(
        (m) => m.UsersManagerModule
      ),
    data: { title: 'Users' },
  },
  {
    path: 'roles',
    loadChildren: () =>
      import('./modules/roles-manager/roles-manager.module').then(
        (m) => m.RolesManagerModule
      ),
    data: { title: 'Roles' },
  },
  {
    path: 'rotations',
    loadChildren: () =>
      import('./modules/rotations-manager/rotations-manager.module').then(
        (m) => m.RotationsManagerModule
      ),
    data: { title: 'Rotations' },
  },
  {
    path: 'notifications',
    loadChildren: () =>
      import(
        './modules/notifications-manager/notifications-manager.module'
      ).then((m) => m.NotificationsManagerModule),
    data: { title: 'Notifications' },
  },
  {
    path: '',
    loadChildren: () =>
      import('./modules/home/home.module').then((m) => m.HomeModule),
    data: { title: 'Antage Interviews' },
  },
  {
    path: 'login',
    component: LoginComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
