/**
 * This module is responsible for signing any HTTP reqeust that is sent to the Interviews API. However, this
 * module should be generic enough that it could be reused for another service with minor tweaking. The assumption would be
 * that the request is of type HTTP 1.1 and the endpoint is an AWS service.
 *
 * The signing process is an implementation of the AWS Signature v4 signing process described here: https://docs.aws.amazon.com/general/latest/gr/signature-version-4.html
 *
 * The steps in the signing process are as follows:
 *
 * 1. Create a canonical request
 *    - The details for this step can be found here: https://docs.aws.amazon.com/general/latest/gr/sigv4-create-canonical-request.html
 *    - The general form of a canonical request:
 *        CanonicalRequest =
 *           HTTPRequestMethod + '\n' +
 *           CanonicalURI + '\n' +
 *           CanonicalQueryString + '\n' +
 *           CanonicalHeaders + '\n' +
 *           SignedHeaders + '\n' +
 *           HexEncode(Hash(RequestPayload))
 * 2. Create a string to sign
 *      - The details for this step can be found here: https://docs.aws.amazon.com/general/latest/gr/sigv4-create-string-to-sign.html
 *      - The general form of a string to sign:
 *           StringToSign =
 *              Algorithm + \n +
 *              RequestDateTime + \n +
 *              CredentialScope + \n +
 *              HashedCanonicalRequest
 * 3. Calculate the signature
 *      - The details for this step can be found here: https://docs.aws.amazon.com/general/latest/gr/sigv4-calculate-signature.html
 *      - The general form of a signature:
 *          kSecret = your secret access key
 *            kDate = HMAC("AWS4" + kSecret, Date)
 *            kRegion = HMAC(kDate, Region)
 *            kService = HMAC(kRegion, Service)
 *            kSigning = HMAC(kService, "aws4_request")
 * 4. Add the signature to the HTTP request
 *      - The details for this step can be found here: https://docs.aws.amazon.com/general/latest/gr/sigv4-add-signature-to-request.html
 *      - The signature is added to the Authorization header of the request which has the following form
 *          Authorization: algorithm Credential=access key ID/credential scope, SignedHeaders=SignedHeaders, Signature=signature
 *
 * Gotchas:
 *
 * - The Host header is required to be part of the HTTP request during the signing process but is should be removed afterwards
 * - If using temporary AWS credentials:
 *    - the session token needs to be added to the X-Amz-Security-Token header
 *    - some AWS services require the X-Amz-Security-Token header to be included in the signing process while others do not
 * - Be sure to check the API reference for the specific service you are sending a request to. There may be other required headers not mentioned here.
 */
import { HttpRequest, HttpHeaders } from '@angular/common/http';
import { ICredentials } from '@aws-amplify/core';

import * as crypto from 'crypto-js';

import { apiHost } from 'src/environments/environment';
import { CanonicalRequest } from './canonicalRequest';
import { hash, hexEncode, hmac } from './encrypt';

const SIGV4_ALGORITHM = 'AWS4-HMAC-SHA256';
const REGION = 'us-east-1';
const SERVICE = 'execute-api';
const HOST = apiHost;
const CONTENT_TYPE_HEADER = 'application/json';

const DEFAULT_HEADERS: { [key: string]: string } = {
  'X-Amz-Date': '',
  'X-Amz-Security-Token': '',
};

function getRequestDateTime(): string {
  return new Date().toISOString().replace(/[:\-]|\.\d{3}/g, '');
}

export class RequestSignerConfig {
  host: string;
  region: string;
  service: string;
  requestDateTime: string;

  constructor(
    host: string,
    region: string,
    service: string,
    requestDateTime: string
  ) {
    this.host = host;
    this.region = region;
    this.service = service;
    this.requestDateTime = requestDateTime;
  }

  static default(): RequestSignerConfig {
    return new RequestSignerConfig(HOST, REGION, SERVICE, getRequestDateTime());
  }

  get requestDate(): string {
    return this.requestDateTime.substr(0, 8);
  }
}

export class RequestSigner {
  private request: HttpRequest<any>;
  private canonicalRequest: CanonicalRequest;
  private credentials: ICredentials;
  private config: RequestSignerConfig;

  constructor(
    request: HttpRequest<any>,
    credentials: ICredentials,
    config: RequestSignerConfig
  ) {
    this.credentials = credentials;
    this.config = config;
    this.request = this.prepareIncomingRequestWithHedersToSign(request);
    this.canonicalRequest = CanonicalRequest.default(this.request);
  }

  static fromConfig(
    request: HttpRequest<any>,
    credentials: ICredentials,
    config: RequestSignerConfig
  ): RequestSigner {
    return new RequestSigner(request, credentials, config);
  }

  static fromDefaultConfig(
    request: HttpRequest<any>,
    credentials: ICredentials
  ): RequestSigner {
    return new RequestSigner(
      request,
      credentials,
      RequestSignerConfig.default()
    );
  }

  private generateRequestWithHeaders(
    request: HttpRequest<any>,
    headers: { [key: string]: string }
  ): HttpRequest<any> {
    let combinedHeaders = { ...headers, ...DEFAULT_HEADERS };

    combinedHeaders['X-Amz-Date'] = this.config.requestDateTime;
    combinedHeaders['X-Amz-Security-Token'] = this.credentials.sessionToken;

    if (request.body) {
      combinedHeaders['Content-Type'] = CONTENT_TYPE_HEADER;
    }

    return request.clone({
      headers: new HttpHeaders(combinedHeaders),
    });
  }

  private prepareIncomingRequestWithHedersToSign(
    request: HttpRequest<any>
  ): HttpRequest<any> {
    return this.generateRequestWithHeaders(request, { Host: this.config.host });
  }

  // Task 1: Create CanonicalRequest
  createCanonicalReqeust(): string {
    return this.canonicalRequest.build();
  }

  createCredentialScope(): string {
    return [
      this.config.requestDate,
      this.config.region,
      this.config.service,
      'aws4_request',
    ].join('/');
  }

  // Task 2: Create a string to sign
  createStringToSign(canonicalRequestString: string): string {
    return [
      SIGV4_ALGORITHM,
      this.config.requestDateTime,
      this.createCredentialScope(),
      hexEncode(hash(canonicalRequestString)),
    ].join('\n');
  }

  generateSigningKey(): crypto.lib.WordArray {
    const kDate = hmac(
      'AWS4' + this.credentials.secretAccessKey,
      this.config.requestDate
    );
    const kRegion = hmac(kDate, this.config.region);
    const kService = hmac(kRegion, this.config.service);
    return hmac(kService, 'aws4_request');
  }

  // Task 3: Calculate request signature
  calculateRequestSignature(stringToSign: string): string {
    let signingKey = this.generateSigningKey();
    return hexEncode(hmac(signingKey, stringToSign));
  }

  createAuthorizationHeader(signature: string): string {
    return [
      SIGV4_ALGORITHM +
        ' Credential=' +
        this.credentials.accessKeyId +
        '/' +
        this.createCredentialScope(),
      'SignedHeaders=' + this.canonicalRequest.signedHeaders(),
      'Signature=' + signature,
    ].join(', ');
  }

  // Task 4 add signature to request
  addSignatureToRequest(signature: string): HttpRequest<any> {
    let authorizationHeader = this.createAuthorizationHeader(signature);
    return this.generateRequestWithHeaders(this.request, {
      Authorization: authorizationHeader,
    });
  }

  signRequest(): HttpRequest<any> {
    let canonicalRequestString = this.createCanonicalReqeust();
    let stringToSign = this.createStringToSign(canonicalRequestString);
    let signature = this.calculateRequestSignature(stringToSign);
    let signedRequest = this.addSignatureToRequest(signature);
    return signedRequest;
  }
}

export function sign(
  request: HttpRequest<any>,
  credentials: ICredentials
): HttpRequest<any> {
  return RequestSigner.fromDefaultConfig(request, credentials).signRequest();
}
