import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { combineLatest, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { createParams } from 'src/app/shared/helpers/createParams';
import {
  NotificationEvent,
  Notification,
  NotificationEventData,
  NotificationOption,
  QueryOptions,
} from 'src/app/shared/models/models.index';
import { HttpErrorService } from 'src/app/shared/services/http-error.service';
import { SendEmailService } from 'src/app/shared/services/send-email/send-email.service';
import { apiUrl } from 'src/environments/environment';
import { Cacheable, CacheBuster } from 'ts-cacheable';
import { Admin } from '../users-manager/services/cognito-wrapper';

const cacheBusterOption$ = new Subject<void>();
const cacheBusterEvent$ = new Subject<void>();
const DAY_TIME = 86400000;
const HOUR_TIME = 3600000;

@Injectable({
  providedIn: 'root',
})
export class NotificationsManagerService {
  admin = new Admin();

  constructor(
    private http: HttpClient,
    private httpError: HttpErrorService,
    private sendEmailService: SendEmailService
  ) {}

  @Cacheable({
    maxAge: DAY_TIME,
  })
  getNotification(notificationId: number): Observable<Notification> {
    return this.http
      .get<Notification[]>(`${apiUrl}/notifications/${notificationId}`, {})
      .pipe(
        // TODO: return single notification object if notificationID provided in the api instead of here
        mergeMap((notifArray) => of(notifArray[0])),
        catchError((err) => this.httpError.handleError(err))
      );
  }

  @Cacheable({
    cacheBusterObserver: cacheBusterEvent$,
    maxAge: HOUR_TIME,
  })
  getNotificationEvents(
    queryOptions: Partial<QueryOptions>
  ): Observable<NotificationEvent[]> {
    const params = createParams(queryOptions);
    return this.http
      .get<NotificationEvent[]>(`${apiUrl}/notification-events`, { params })
      .pipe(catchError((err) => this.httpError.handleError(err)));
  }

  /**
   * Helper function that gets the pre-configured notification and passes it along to the createNotificationEvent function for POSTing
   *
   * @param notificationID The ID for the pre-configured notification that will have its fields overwritten
   * @param targetUser The user to send the notification to
   * @param notificationEventData The fields to overwrite in the pre-configured notification
   * @returns The result of the createNotificationEvent function
   */
  triggerNotificationEvent(
    notificationID: number,
    targetUser: string,
    notificationEventData: NotificationEventData,
    organizationID: number
  ): Observable<NotificationEvent> {
    return this.getNotification(notificationID).pipe(
      mergeMap((notification) => {
        const newNotificationEvent: NotificationEvent = {
          NotificationEventUser: targetUser,
          NotificationEventIsRead: false,
          NotificationID: notificationID,
          NotificationEventLong: '',
          NotificationEventShort: '',
          OrganizationID: organizationID,
        };
        return this.createNotificationEvent(
          newNotificationEvent,
          notification,
          notificationEventData
        );
      })
    );
  }

  /**
   * Creates a notification event based on the pre-configured notification and the additional data to overwrite the fields inside the notification
   *
   * @param notificationEvent A new event that holds the target user
   * @param notification The pre-configured notification selected
   * @param notificationEventData Additional data that will overwrite specific fields in the pre-configured notification
   * @returns The results of the post to the notifciation-events endpoint
   */
  @CacheBuster({
    cacheBusterNotifier: cacheBusterEvent$,
  })
  private createNotificationEvent(
    notificationEvent: NotificationEvent,
    notification: Notification,
    notificationEventData: NotificationEventData
  ): Observable<NotificationEvent> {
    const body = { notificationEvent, notification, notificationEventData };
    return this.http
      .post<NotificationEvent>(`${apiUrl}/notification-events`, body)
      .pipe(
        mergeMap((postedEvent) => of(postedEvent)),
        catchError((err) => this.httpError.handleError(err))
      );
  }

  @CacheBuster({
    cacheBusterNotifier: cacheBusterEvent$,
  })
  updateNotificationEvents(
    queryOptions: Pick<QueryOptions, 'notificationEventIDs'>
  ): Observable<any> {
    const params = createParams(queryOptions);
    return this.http
      .put<any>(`${apiUrl}/notification-events`, null, { params })
      .pipe(catchError((err) => this.httpError.handleError(err)));
  }

  @Cacheable({
    cacheBusterObserver: cacheBusterOption$,
    maxAge: DAY_TIME,
  })
  getNotificationOptions(
    queryOptions: Pick<QueryOptions, 'userID'>
  ): Observable<NotificationOption[]> {
    const params = createParams(queryOptions);
    return this.http
      .get<NotificationOption[]>(`${apiUrl}/notification-options`, { params })
      .pipe(catchError((err) => this.httpError.handleError(err)));
  }

  createNotificationOptions(
    queryOptions: Pick<QueryOptions, 'userID'>
  ): Observable<any> {
    const params = createParams(queryOptions);
    return this.http
      .post<NotificationOption[]>(`${apiUrl}/notification-options`, null, {
        params,
      })
      .pipe(catchError((err) => this.httpError.handleError(err)));
  }

  @CacheBuster({
    cacheBusterNotifier: cacheBusterOption$,
  })
  updateNotificationOptions(
    notificationOptions: NotificationOption[]
  ): Observable<any> {
    return this.http
      .put<NotificationOption[]>(
        `${apiUrl}/notification-options`,
        notificationOptions
      )
      .pipe(catchError((err) => this.httpError.handleError(err)));
  }
}
