import { Injectable } from '@angular/core';
import { map, filter, tap } from 'rxjs/operators';
import { MonacoEditorLoaderService } from '@materia-ui/ngx-monaco-editor';
import { Cacheable } from 'ts-cacheable';
import { BehaviorSubject } from 'rxjs';
import { Language } from '../../models/language.interface';

const DAY_TIME = 86400000;
@Injectable({
  providedIn: 'root',
})
export class MonacoEditorService {
  private _languages$ = new BehaviorSubject<Language[]>([]);
  readonly languages$ = this._languages$.asObservable();

  constructor(private monacoLoaderService: MonacoEditorLoaderService) {}

  get languages() {
    return this._languages$.value;
  }

  loadData() {
    return this.getMonacoLanguages().pipe(
      tap((languages) => this._languages$.next(languages))
    );
  }

  @Cacheable({
    maxAge: DAY_TIME,
    slidingExpiration: true,
  })
  getMonaco() {
    return this.monacoLoaderService.isMonacoLoaded$.pipe(
      filter((isLoaded) => isLoaded),
      map(() => monaco)
    );
  }

  @Cacheable({
    maxAge: DAY_TIME,
    slidingExpiration: true,
  })
  getMonacoLanguages() {
    return this.getMonaco().pipe(
      map((monaco) => monaco.languages.getLanguages())
    );
  }
}
