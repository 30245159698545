import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from 'src/app/auth/auth.interceptor';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MatNativeDateModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatChipsModule } from '@angular/material/chips';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatListModule } from '@angular/material/list';
import { MatTableModule } from '@angular/material/table';
import { MatDialogModule } from '@angular/material/dialog';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatMenuModule } from '@angular/material/menu';
import { MatSliderModule } from '@angular/material/slider';
import { MatRadioModule } from '@angular/material/radio';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MonacoEditorModule } from '@materia-ui/ngx-monaco-editor';
import { MainHeaderComponent } from '../shared/components/main-header/main-header.component';
import { SideNavComponent } from '../shared/components/side-nav/side-nav.component';
import { ConfirmationDialogComponent } from '../shared/components/confirmation-dialog/confirmation-dialog.component';
import { AlertDialogComponent } from '../shared/components/alert-dialog/alert-dialog.component';
import { ImpactConfirmationDialogComponent } from '../shared/components/impact-confirmation-dialog/impact-confirmation-dialog.component';
import { PaginatedQuestionsSidenavComponent } from '../shared/components/paginated-questions-sidenav/paginated-questions-sidenav.component';
import { SendEmailComponent } from '../shared/components/send-email/send-email.component';
import { EditorModule } from '@tinymce/tinymce-angular';
import { ButtonComponent } from 'src/app/shared/components/button/button.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { MatCardModule } from '@angular/material/card';
import { PlainTextEditorComponent } from 'src/app/shared/components/plain-text-editor/plain-text-editor.component';

const sharedModules = [
  CommonModule,
  HttpClientModule,
  FormsModule,
  ReactiveFormsModule,
  RouterModule,
  FontAwesomeModule,
  MonacoEditorModule,
  MatInputModule,
  MatSelectModule,
  MatFormFieldModule,
  MatButtonModule,
  MatSnackBarModule,
  MatCheckboxModule,
  MatChipsModule,
  MatListModule,
  MatExpansionModule,
  MatTableModule,
  MatDialogModule,
  MatPaginatorModule,
  FlexLayoutModule,
  MatSliderModule,
  MatSortModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatTooltipModule,
  MatSidenavModule,
  MatProgressBarModule,
  MatToolbarModule,
  MatMenuModule,
  MatRadioModule,
  MatCheckboxModule,
  EditorModule,
  ScrollingModule,
  MatProgressSpinnerModule,
  PipesModule,
  MatCardModule,
];

const sharedComponents = [
  MainHeaderComponent,
  SideNavComponent,
  ConfirmationDialogComponent,
  AlertDialogComponent,
  ImpactConfirmationDialogComponent,
  PaginatedQuestionsSidenavComponent,
  SendEmailComponent,
  ButtonComponent,
  PlainTextEditorComponent,
];

@NgModule({
  declarations: [...sharedComponents],
  imports: [...sharedModules],
  exports: [...sharedModules, ...sharedComponents],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
  ],
})
export class CoreModule {}
