import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subscription, first } from 'rxjs';
import { Question } from 'src/app/shared/models/models.index';
import { QuestionsBuilderService } from '../../questions-builder.service';
import { QuestionsModalComponent } from '../questions-modal/questions-modal.component';
import { QuestionDialogData } from '../questions-modal/questions-modal.component';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NonNullableFormBuilder } from '@angular/forms';

@Component({
  selector: 'app-questions-list',
  templateUrl: './questions-list.component.html',
  styleUrls: ['./questions-list.component.css'],
})
export class QuestionsListComponent implements OnInit, OnDestroy {
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  displayedQuestionData: string[] = [
    'ID',
    'Name',
    'Description',
    'Question Type',
    'Archived?',
    'Actions',
  ];

  displayedColumns = [
    'QuestionID',
    'QuestionName',
    'QuestionDescription',
    'QuestionTypeName',
    'DeletedDateTime',
    'Actions',
  ];

  subscription: Subscription = new Subscription();
  currentQuestionsList = false;
  isLoaded = false;

  protected questionFilterForm = this.fb.group({
    Text: this.fb.control<string>(''),
    HideArchived: this.fb.control<boolean>(false),
  });

  protected dataSource = new MatTableDataSource<Question>();

  constructor(
    private cdr: ChangeDetectorRef,
    private dialog: MatDialog,
    private fb: NonNullableFormBuilder,
    private questionsBuilderService: QuestionsBuilderService
  ) {}

  ngOnInit(): void {
    this.getQuestions();

    this.questionsBuilderService
      .getQuestions({ includeDeleted: true })
      .pipe(first())
      .subscribe(() => this.initTable());

    this.subscription.add(
      this.questionFilterForm.valueChanges.subscribe(() => this.applyFilter())
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  initTable() {
    this.isLoaded = true;
    this.cdr.detectChanges();
    this.dataSource.filterPredicate = (data) => this.filterPredicate(data);
    this.dataSource.sort = this.sort;
    this.dataSource.sortingDataAccessor = this.sortingDataAccessor;
    this.dataSource.paginator = this.paginator;
  }

  private applyFilter() {
    this.dataSource.filter = `filter`;
  }

  private filterPredicate(question: Question) {
    return (
      this.filterSearchText(question) && this.filterArchivedQuestion(question)
    );
  }

  private filterSearchText(question: Question) {
    const value = this.questionFilterForm.controls.Text.value;
    return !!value
      ? question.QuestionName.toLocaleLowerCase().includes(
          value.toLocaleLowerCase()
        )
      : true;
  }

  private filterArchivedQuestion(question: Question) {
    const hideArchived = this.questionFilterForm.controls.HideArchived.value;
    return hideArchived ? !question.DeletedDateTime : true;
  }

  private sortingDataAccessor(item: Question, property: string) {
    const questionProperty = property as keyof Question;
    if (questionProperty === 'QuestionType') {
      return item.QuestionType.QuestionTypeName.toLowerCase();
    } else if (questionProperty === 'DeletedDateTime') {
      return item.DeletedDateTime
        ? `2${item?.QuestionID}`
        : `1${item?.QuestionID}`;
    } else {
      return typeof item[questionProperty] === 'string'
        ? (item[questionProperty] as string).toLowerCase()
        : '';
    }
  }

  onAction(
    question: Question,
    action: 'create' | 'view' | 'edit' | 'delete' | 'undelete'
  ): void {
    const data: QuestionDialogData = { question, action };
    const dialogRef = this.dialog.open(QuestionsModalComponent, { data });
    dialogRef.afterClosed().subscribe(() => this.getQuestions());
  }

  getQuestions(): void {
    this.subscription.add(
      this.questionsBuilderService
        .getQuestions({ includeDeleted: true })
        .subscribe((questions) => {
          this.dataSource.data = questions;
        })
    );
  }
}
