<form class="plain-text__form" [formGroup]="form">
  <editor
    class="plain-text__editor"
    ngDefaultControl
    formControlName="plainTextAnswer"
    outputFormat="html"
    [apiKey]="tinyMceApiKey"
    [init]="editorOptions"
    [attr.disabled]="true"
  ></editor>
</form>
