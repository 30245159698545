import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Email } from 'src/app/shared/models/models.index';
import { tinyMceApiKey } from 'src/environments/environment';

@Component({
  selector: 'app-send-email',
  templateUrl: './send-email.component.html',
  styleUrls: ['./send-email.component.css'],
})
export class SendEmailComponent implements OnInit {
  @Input() email!: Email;
  @Output() emailChange = new EventEmitter<Email>();

  tinyMceApiKey = tinyMceApiKey;

  editorOptions = {
    height: 300,
    menubar: false,
    plugins: [
      'lists advlist autolink link image charmap print',
      'preview anchor searchreplace visualblocks code',
      'fullscreen insertdatetime media table paste',
      'help wordcount',
    ],
    toolbar: `undo redo | fontselect fontsizeselect |
        bold italic | formatselect | link |
        alignleft aligncenter alignright alignjustify |
        bullist numlist outdent indent | help`,
  };

  emailForm = this.fb.group({
    emailSubject: ['', Validators.required],
    emailBody: ['', Validators.required],
  });

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    if (this.email.Subject) {
      this.emailForm.controls.emailSubject.setValue(this.email.Subject);
    }

    if (this.email.Body) {
      this.emailForm.controls.emailBody.setValue(this.email.Body);
    }

    this.emailForm.valueChanges.subscribe(() => {
      this.emailChange.emit({
        Subject: this.emailForm.controls.emailSubject.value
          ? this.emailForm.controls.emailSubject.value
          : undefined,
        Body: this.emailForm.controls.emailBody.value
          ? this.emailForm.controls.emailBody.value
          : undefined,
      });
    });
  }
}
