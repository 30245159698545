import {
  Component,
  OnInit,
  ChangeDetectorRef,
  NgZone,
  OnDestroy,
} from '@angular/core';
import { Hub, I18n } from '@aws-amplify/core';
import {
  onAuthUIStateChange,
  CognitoUserInterface,
  AuthState,
  FormFieldTypes,
} from '@aws-amplify/ui-components';
import { AuthenticatorService } from '@aws-amplify/ui-angular';
import { Router } from '@angular/router';

import { RouteService } from 'src/app/shared/services/route/route.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit, OnDestroy {
  user: CognitoUserInterface | undefined;
  authState!: AuthState;
  public formFields = {
    signIn: {
      username: {
        labelHidden: false,
        placeholder: 'Enter Your Email',
        isRequired: true,
        label: 'Email *',
      },
      password: {
        labelHidden: false,
        placeholder: 'Enter Your Password',
        isRequired: true,
        label: 'Password *',
      },
    },
  };

  constructor(
    public authenticator: AuthenticatorService,
    private ref: ChangeDetectorRef,
    private routeService: RouteService,
    private router: Router,
    private zone: NgZone
  ) {}

  ngOnInit() {
    I18n.putVocabulariesForLanguage('en', {
      'Sign In': 'Login',
      'Sign in': 'Log in',
      'Sign in to your account': 'Welcome Back!',
      'Forgot your password?': 'Reset Password',
    });

    const onAuthUIStateChange = (data: any) => {
      this.authState = data.payload.event;
      this.user = data.payload.data as CognitoUserInterface;
      this.ref.detectChanges();

      if (data.payload.event === 'signIn') {
        this.zone.run(() =>
          this.router.navigate([`${this.routeService.redirectUrl}`])
        );
      }
    };

    Hub.listen('auth', onAuthUIStateChange);
  }

  ngOnDestroy() {
    return onAuthUIStateChange;
  }

  toSignUp(): void {
    this.zone.run(() => this.router.navigate(['/register']));
  }
}
