<div>
  <form [formGroup]="questionFilterForm" fxLayout fxLayoutAlign="left">
    <mat-form-field fxFlex="20%">
      <input matInput formControlName="Text" type="text" placeholder="Filter" />
    </mat-form-field>
    <mat-checkbox class="current-question-list" formControlName="HideArchived">
      Hide Archived
    </mat-checkbox>
  </form>
  <div class="table-container mat-elevation-z2" *ngIf="isLoaded; else loading">
    <table
      class="table"
      mat-table
      [dataSource]="dataSource"
      matSort
      matSortActive="QuestionArchive"
      matSortDirection="asc"
      id="questionsTable"
    >
      <ng-container sticky matColumnDef="QuestionID">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>ID</th>
        <td mat-cell *matCellDef="let question">
          {{ question.QuestionID }}
        </td>
      </ng-container>

      <ng-container sticky matColumnDef="QuestionName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="QuestionName">
          Name
        </th>
        <td mat-cell *matCellDef="let question">
          {{
            question.QuestionName.length <= 25
              ? question.QuestionName
              : question.QuestionName.slice(0, 26) + "..."
          }}
        </td>
      </ng-container>

      <ng-container sticky matColumnDef="QuestionDescription">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Description</th>
        <td mat-cell *matCellDef="let question">
          {{
            question.QuestionDescription.length <= 75
              ? question.QuestionDescription
              : question.QuestionDescription.slice(0, 76) + "..."
          }}
        </td>
      </ng-container>

      <ng-container sticky matColumnDef="DeletedDateTime">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Archive Status
        </th>
        <td mat-cell *matCellDef="let question">
          {{ question.DeletedDateTime ? "Archived" : "Current" }}
        </td>
      </ng-container>

      <ng-container sticky matColumnDef="QuestionTypeName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Type</th>
        <td mat-cell *matCellDef="let question">
          {{ question.QuestionType.QuestionTypeName }}
        </td>
      </ng-container>

      <ng-container sticky matColumnDef="Actions">
        <th mat-header-cell *matHeaderCellDef>Actions</th>
        <td mat-cell *matCellDef="let question">
          <button
            mat-stroked-button
            class="list-action"
            (click)="onAction(question, 'view')"
          >
            View
          </button>
          <button
            mat-stroked-button
            class="list-action"
            (click)="onAction(question, 'edit')"
          >
            Edit
          </button>
          <button
            mat-stroked-button
            class="list-action"
            (click)="
              question.DeletedDateTime
                ? onAction(question, 'undelete')
                : onAction(question, 'delete')
            "
          >
            {{ question.DeletedDateTime ? "Un-Archive" : "Archive" }}
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    <mat-paginator
      [pageSize]="10"
      [pageSizeOptions]="[5, 10, 25, 100]"
    ></mat-paginator>
  </div>
</div>
<div *ngIf="dataSource.data.length === 0 && isLoaded">
  <p class="questionsEmptyViewWarning">No records to display.</p>
</div>
<ng-template #loading>
  <div class="spinner-wrapper" fxLayout="row" fxLayoutAlign="center center">
    <mat-spinner class="spinner"></mat-spinner>
  </div>
</ng-template>
