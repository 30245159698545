<mat-sidenav
  [@sideNavTrigger]="isOpen ? 'open' : 'closed'"
  #sidenav
  opened
  mode="over"
  hasBackdrop="false"
  fixedInViewport="true"
  class="side-nav"
>
  <div id="logo-wrapper">
    <img
      [ngClass]="isOpen ? '' : 'hidden'"
      alt="Antage, Inc."
      id="logo"
      src="assets/images/antage.png"
    />
  </div>
  <div
    fxLayout="column"
    [fxLayoutAlign]="isOpen ? 'center center' : 'center end'"
  >
    <div class="side-nav__list-item">
      <button
        mat-stroked-button
        [routerLink]="['/']"
        class="side-nav__button styled"
        [ngClass]="{
          icon: !isOpen,
          'nav-selected': currentPath() === ''
        }"
        [@sideNavButtonTrigger]="isOpen ? 'open' : 'closed'"
        id="side-nav__button--home"
        matTooltip="Home"
        [matTooltipDisabled]="isOpen"
        matTooltipPosition="right"
      >
        <ng-container *ngIf="isOpen">Home</ng-container>
        <fa-icon *ngIf="!isOpen" [icon]="homeIcon"></fa-icon>
      </button>
    </div>
    <div class="side-nav__list-item">
      <button
        mat-stroked-button
        [routerLink]="['/questions']"
        class="side-nav__button styled"
        [ngClass]="{
          icon: !isOpen,
          'nav-selected': currentPath() === 'questions'
        }"
        [@sideNavButtonTrigger]="isOpen ? 'open' : 'closed'"
        id="side-nav__button--questions"
        matTooltip="Questions"
        [matTooltipDisabled]="isOpen"
        matTooltipPosition="right"
      >
        <ng-container *ngIf="isOpen">Questions</ng-container>
        <fa-icon *ngIf="!isOpen" [icon]="questionsIcon"></fa-icon>
      </button>
    </div>
    <div class="side-nav__list-item">
      <button
        mat-stroked-button
        [routerLink]="['/assessments']"
        class="side-nav__button styled"
        [ngClass]="{
          icon: !isOpen,
          'nav-selected': currentPath() === 'assessments'
        }"
        [@sideNavButtonTrigger]="isOpen ? 'open' : 'closed'"
        id="side-nav__button--assessments"
        matTooltip="Assessments"
        [matTooltipDisabled]="isOpen"
        matTooltipPosition="right"
      >
        <ng-container *ngIf="isOpen">Assessments</ng-container>
        <fa-icon *ngIf="!isOpen" [icon]="assessmentsIcon"></fa-icon>
      </button>
    </div>
    <div class="side-nav__list-item">
      <button
        mat-stroked-button
        [routerLink]="['/invitations']"
        class="side-nav__button styled"
        [ngClass]="{
          icon: !isOpen,
          'nav-selected': currentPath() === 'invitations'
        }"
        [@sideNavButtonTrigger]="isOpen ? 'open' : 'closed'"
        id="side-nav__button--invitations"
        matTooltip="Invitations"
        [matTooltipDisabled]="isOpen"
        matTooltipPosition="right"
      >
        <ng-container *ngIf="isOpen">Invitations</ng-container>
        <fa-icon *ngIf="!isOpen" [icon]="invitationsIcon"></fa-icon>
      </button>
    </div>
    <div class="side-nav__list-item">
      <button
        mat-stroked-button
        [routerLink]="['/reviews']"
        class="side-nav__button styled"
        [ngClass]="{
          icon: !isOpen,
          'nav-selected': currentPath() === 'reviews'
        }"
        [@sideNavButtonTrigger]="isOpen ? 'open' : 'closed'"
        id="side-nav__button--reviews"
        matTooltip="Reviews"
        [matTooltipDisabled]="isOpen"
        matTooltipPosition="right"
      >
        <ng-container *ngIf="isOpen">Reviews</ng-container>
        <fa-icon *ngIf="!isOpen" [icon]="reviewsIcon"></fa-icon>
      </button>
    </div>
    <!-- [icon]="isOpen ? openIcon : closedIcon" -->
    <fa-icon
      fxFlexAlign="end"
      (click)="service.toggleIsOpen()"
      class="open-close-arrow"
      [@sideNavArrowTrigger]="isOpen ? 'open' : 'closed'"
      [icon]="arrowIcon"
      [matTooltip]="isOpen ? 'Collapse' : 'Expand'"
      matTooltipPosition="right"
    ></fa-icon>
  </div>
</mat-sidenav>
