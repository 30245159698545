export const environment = {
  production: false,
};

export const apiHost: string = '6g38k4tetg.execute-api.us-east-1.amazonaws.com';
export const apiUrl: string =
  'https://6g38k4tetg.execute-api.us-east-1.amazonaws.com/uat/api';
export { tinyMceApiKey } from './environment.dev';

export const cognitoConfig = {
  aws_cognito_region: 'us-east-1',
  aws_user_pools_id: 'us-east-1_8ycFOcoG5',
  aws_user_pools_web_client_id: '4uc8b22mmalo68uhkts60sn1mt',
  aws_cognito_identity_pool_id:
    'us-east-1:58e79b8b-7325-42c4-b0cd-5cc8817a3731',
  API: {
    // TODO: dynamically create these endpoints from given environment
    endpoints: [
      {
        name: 'uat-parratus-api',
        endpoint: apiUrl,
      },
    ],
  },
};
