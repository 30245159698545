import {
  Component,
  OnChanges,
  ChangeDetectorRef,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
  ElementRef,
  Renderer2,
  ViewChild,
  AfterViewInit,
  AfterContentInit,
  OnDestroy,
  HostBinding,
} from '@angular/core';

import { fromEvent, Subscription } from 'rxjs';

import { Colors, ColorValues } from 'src/app/shared/constants.enum';

export interface ButtonColorTheme {
  default: {
    backgroundColor: string;
    color: string;
    borderColor: string;
  };
  onHover: {
    backgroundColor: string;
    color: string;
    borderColor: string;
  };
  disabled: {
    backgroundColor: string;
    color: string;
    borderColor: string;
  };
}

export enum ButtonColorThemes {
  Primary = 1,
  Secondary = 2,
  Critical = 3,
}

const ButtonColorThemeValues = {
  1: {
    default: {
      backgroundColor: ColorValues[Colors.Primary],
      color: 'white',
      borderColor: ColorValues[Colors.PrimaryDark],
    },
    onHover: {
      backgroundColor: ColorValues[Colors.SecondaryAlt],
      color: 'white',
      borderColor: ColorValues[Colors.SecondaryDark],
    },
    disabled: {
      backgroundColor: ColorValues[Colors.Primary],
      color: 'white',
      borderColor: ColorValues[Colors.PrimaryDark],
    },
  },
  2: {
    default: {
      backgroundColor: ColorValues[Colors.Secondary],
      color: 'black',
      borderColor: ColorValues[Colors.Primary],
    },
    onHover: {
      backgroundColor: ColorValues[Colors.SecondaryAlt],
      color: 'black',
      borderColor: ColorValues[Colors.SecondaryDark],
    },
    disabled: {
      backgroundColor: ColorValues[Colors.Secondary],
      color: 'black',
      borderColor: ColorValues[Colors.Primary],
    },
  },
  3: {
    default: {
      backgroundColor: ColorValues[Colors.Primary],
      color: 'white',
      borderColor: ColorValues[Colors.PrimaryDark],
    },
    onHover: {
      backgroundColor: ColorValues[Colors.PrimaryDark],
      color: 'white',
      borderColor: ColorValues[Colors.SecondaryDark],
    },
    disabled: {
      backgroundColor: ColorValues[Colors.Primary],
      color: 'white',
      borderColor: ColorValues[Colors.PrimaryDark],
    },
  },
};

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.css'],
})
export class ButtonComponent
  implements OnChanges, AfterViewInit, OnDestroy, AfterContentInit
{
  @ViewChild('wrapper') wrapper!: ElementRef;
  @Input() selected = false;
  @Input() theme: ButtonColorThemes = 1;
  @Input() disabled = false;
  @HostBinding('style.pointerEvents') hostPointerEvents!: any;

  el!: HTMLElement;
  themeStyles!: ButtonColorTheme;
  subscription = new Subscription();

  constructor(private renderer: Renderer2, private cd: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.el) return;

    if (changes.selected) {
      this.toggleSelected();
    }

    if (changes.disabled) {
      this.togglePointerEvents();
      this.toggleDisabled();
    }
  }

  ngAfterContentInit() {
    this.hostPointerEvents = this.disabled ? 'none' : 'auto';
    this.cd.detectChanges();
  }

  ngAfterViewInit() {
    this.el = this.wrapper.nativeElement;

    this.themeStyles = ButtonColorThemeValues[this.theme];

    this.renderStyles({ ...this.themeStyles.default });

    this.toggleSelected();

    this.toggleDisabled();

    ['mouseenter', 'mouseleave', 'mousedown', 'mouseup'].forEach((event) => {
      this.subscription.add(
        fromEvent(this.el, event).subscribe(() => this.handleMouseEvent(event))
      );
    });
  }

  toggleDisabled() {
    if (this.disabled) {
      this.renderStyles({ ...this.themeStyles.default, opacity: '0.6' });
    } else {
      this.renderStyles({ opacity: '1' });
    }
  }

  togglePointerEvents() {
    if (this.disabled) {
      this.hostPointerEvents = 'none';
      this.cd.detectChanges();
    } else {
      this.hostPointerEvents = 'auto';
      this.cd.detectChanges();
    }
  }

  toggleSelected() {
    this.selected
      ? this.renderer.setStyle(this.el, 'borderStyle', 'solid')
      : this.renderer.setStyle(this.el, 'borderStyle', 'none');
  }

  handleMouseEvent(event: string) {
    if (!this.disabled) {
      switch (event) {
        case 'mouseenter':
          this.renderStyles({ ...this.themeStyles.onHover });
          break;
        case 'mouseleave':
          this.renderStyles({ ...this.themeStyles.default });
          break;
        case 'mousedown':
          this.renderer.addClass(this.el, 'active');
          break;
        case 'mouseup':
          this.renderer.removeClass(this.el, 'active');
          break;
      }
    }
  }

  renderStyles(styleObject: Record<string, string>) {
    Object.entries(styleObject).forEach((entry) =>
      this.renderer.setStyle(this.el, entry[0], entry[1])
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
