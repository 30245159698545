import { Component } from '@angular/core';
import { SideNavContentAnimation } from 'src/app/shared/components/side-nav/side-nav.animations';
import { SideNavService } from 'src/app/shared/components/side-nav/side-nav.service';

@Component({
  selector: 'app-view-questions',
  templateUrl: './view-questions.component.html',
  styleUrls: ['./view-questions.component.css'],
  animations: [SideNavContentAnimation],
})
export class ViewQuestionsComponent {
  constructor(public sidenav: SideNavService) {}
}
