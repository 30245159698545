import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { apiUrl } from 'src/environments/environment';
import {
  Invitation,
  InvitationStatus,
} from 'src/app/shared/models/models.index';
import { HttpErrorService } from 'src/app/shared/services/http-error.service';
import {
  UserActivityService,
  ResourceType,
} from 'src/app/shared/services/user-activity/user-activity.service';
import { QueryOptions } from 'src/app/shared/models/models.index';
import { Cacheable, CacheBuster } from 'ts-cacheable';

const cacheBuster$ = new Subject<void>();
const HOUR_TIME = 3600000;
const DAY_TIME = 86400000;

@Injectable({
  providedIn: 'root',
})
export class InvitationsBuilderService {
  constructor(
    private http: HttpClient,
    private httpError: HttpErrorService,
    private userActivityService: UserActivityService
  ) {}

  @CacheBuster({
    cacheBusterNotifier: cacheBuster$,
  })
  saveInvitation(invitation: Invitation): Observable<Invitation> {
    return this.userActivityService.createResource(
      invitation,
      ResourceType.INVITATION
    );
  }

  @Cacheable({
    cacheBusterObserver: cacheBuster$,
    maxAge: HOUR_TIME,
    slidingExpiration: true,
    maxCacheCount: 2,
  })
  getInvitation(id: number): Observable<Invitation> {
    return this.userActivityService.readResource(ResourceType.INVITATION, {
      resourceId: id,
    });
  }

  @Cacheable({
    cacheBusterObserver: cacheBuster$,
    maxAge: HOUR_TIME,
    slidingExpiration: true,
  })
  getInvitations(): Observable<Invitation[]> {
    return this.userActivityService.readResource(ResourceType.INVITATION);
  }

  @Cacheable({
    cacheBusterObserver: cacheBuster$,
    maxAge: HOUR_TIME,
    slidingExpiration: true,
  })
  getInvitationsByAssessmentID(
    queryOptions: Pick<QueryOptions, 'assessmentID'>
  ): Observable<Invitation[]> {
    return this.userActivityService.readResource(ResourceType.INVITATION, {
      queryOptions,
    });
  }

  @CacheBuster({
    cacheBusterNotifier: cacheBuster$,
  })
  updateInvitation(id: number, invitation: Invitation): Observable<Invitation> {
    return this.userActivityService.updateResource(
      id,
      invitation,
      ResourceType.INVITATION
    );
  }

  @CacheBuster({
    cacheBusterNotifier: cacheBuster$,
  })
  deleteInvitation(id: number): Observable<any> {
    return this.userActivityService.deleteResource(id, ResourceType.INVITATION);
  }

  @CacheBuster({
    cacheBusterNotifier: cacheBuster$,
  })
  undeleteInvitation(
    id: number,
    invitation: Invitation
  ): Observable<Invitation> {
    return this.userActivityService.updateResource(
      id,
      invitation,
      ResourceType.INVITATION
    );
  }

  @Cacheable({
    cacheBusterObserver: cacheBuster$,
    maxAge: DAY_TIME,
    slidingExpiration: true,
  })
  getInvitationStatuses(): Observable<InvitationStatus[]> {
    return this.http
      .get<InvitationStatus[]>(`${apiUrl}/invitation-statuses`)
      .pipe(catchError((err) => this.httpError.handleError(err)));
  }
}
