import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import {
  SideNavAnimation,
  SideNavButtonAnimation,
  SideNavArrowAnimation,
} from './side-nav.animations';
import { SideNavService } from './side-nav.service';
import {
  faHome,
  faQuestion,
  faTasks,
  faEnvelope,
  faChartBar,
  faArrowAltCircleLeft,
  faArrowAltCircleRight,
} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.css'],
  animations: [SideNavAnimation, SideNavButtonAnimation, SideNavArrowAnimation],
})
export class SideNavComponent implements OnInit {
  userIsLoggedIn = false;
  isOpen = true;
  subscription: Subscription = new Subscription();
  homeIcon = faHome;
  questionsIcon = faQuestion;
  assessmentsIcon = faTasks;
  invitationsIcon = faEnvelope;
  reviewsIcon = faChartBar;
  arrowIcon = faArrowAltCircleLeft;

  constructor(public service: SideNavService) {}

  ngOnInit() {
    this.subscription.add(
      this.service.isOpen$.subscribe((openState) => {
        this.isOpen = openState;
      })
    );
  }

  // This is a fairly inefficient way to do this, but it's probably okay
  currentPath() {
    const path = window.location.pathname.split('/');
    if (path.length > 1) {
      return path[1];
    } else {
      return path[0];
    }
  }
}
