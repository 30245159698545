<form class="email-form" [formGroup]="emailForm">
  <mat-form-field class="email-subject__form-field email-form-field">
    <mat-label>Email Subject</mat-label>
    <input matInput type="text" formControlName="emailSubject" required />
  </mat-form-field>

  <editor
    class="email-body__form-field email-form-field"
    ngDefaultControl
    formControlName="emailBody"
    [apiKey]="tinyMceApiKey"
    [init]="editorOptions"
  ></editor>
</form>
