import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import {
  Question,
  Response,
  ResponseTracking,
} from 'src/app/shared/models/models.index';
import { faCheckCircle, faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import { faCircle } from '@fortawesome/free-regular-svg-icons';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'app-paginated-questions-sidenav',
  templateUrl: './paginated-questions-sidenav.component.html',
  styleUrls: ['./paginated-questions-sidenav.component.css'],
})
export class PaginatedQuestionsSidenavComponent implements OnInit, OnChanges {
  @Input() questions: Question[] = [];
  @Input() response: Response | null = null;
  @Input() readOnly: boolean = false;
  @Input() questionNumber$: Observable<number> = of(0);
  @Output() questionChange = new EventEmitter<number>();
  @Output() submitChange = new EventEmitter<void>();
  answeredRTs: (ResponseTracking | null)[] = [];
  circle = faCircle;
  checkCircle = faCheckCircle;
  ellipsis = faEllipsisH;
  questionNumber = 0;

  get responseTrackings(): ResponseTracking[] {
    return this.response && this.response.ResponseTrackings
      ? this.response.ResponseTrackings
      : [];
  }

  ngOnInit(): void {
    this.questionNumber$.subscribe((questionNumber) => {
      this.questionNumber = questionNumber;
    });
  }

  ngOnChanges(): void {
    this.answeredRTs = this.questions.map((question) => {
      const matchedRT = this.responseTrackings.find(
        (rt) => rt.QuestionID === question.QuestionID
      );
      return matchedRT ? matchedRT : null;
    });
  }

  passQuestionChange(questionNumber: number): void {
    this.questionNumber = questionNumber;
    this.questionChange.emit(questionNumber);
  }

  triggerSubmit(): void {
    this.submitChange.emit();
  }
}
