<app-side-nav></app-side-nav>
<div
  class="viewQuestionsBody"
  [@sideNavContentTrigger]="sidenav.getIsOpen() ? 'open' : 'closed'"
>
  <div class="viewQuestionsMain">
    <button
      mat-stroked-button
      [routerLink]="['./create']"
      id="create_question_button"
    >
      Create Question
    </button>
    <app-questions-list></app-questions-list>
  </div>
</div>
