import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-notifications-modal',
  templateUrl: './notifications-modal.component.html',
  styleUrls: ['./notifications-modal.component.css'],
})
export class NotificationsModalComponent implements OnInit {
  maxNotificationsShown: number = 3;

  constructor(public dialogRef: MatDialogRef<NotificationsModalComponent>) {}

  ngOnInit(): void {
    this.changePosition();
  }

  onClose(e: any): void {
    this.dialogRef.close(e);
  }

  changePosition() {
    this.dialogRef.updatePosition({ top: '50px', right: '50px' });
  }
}
