import { CognitoIdentityServiceProvider } from 'aws-sdk';
import { Auth } from 'aws-amplify';
import {
  awsConfig,
  params,
  userAttributes,
} from 'src/app/shared/helpers/aws-config';
import { isNotUndefined } from 'src/app/shared/helpers/isNotUndefined';
import { flatten$ } from '../helpers/rxjs-helpers';
import { UserGroupType } from 'src/app/shared/models/models.index';
import {
  RETURN_EMPTY,
  catchNotAuthorized,
} from '../helpers/not-authorized.error';
import {
  UserType,
  AdminUpdateUserAttributesResponse,
  AdminListGroupsForUserResponse,
  AdminEnableUserResponse,
  AdminDisableUserResponse,
  ListGroupsResponse,
  ListUsersInGroupResponse,
  AdminResetUserPasswordResponse,
  AdminUpdateUserAttributesRequest,
  GroupType,
  AttributeType,
  ListUsersResponse,
  AdminCreateUserRequest,
  AdminCreateUserResponse,
  ListUsersRequest,
} from 'aws-sdk/clients/cognitoidentityserviceprovider';
import { throwError, of, Observable, forkJoin, from } from 'rxjs';
import {
  groupBy,
  filter,
  concatAll,
  mergeMap,
  toArray,
  switchMap,
  map,
} from 'rxjs/operators';
import { cognitoConfig } from 'src/environments/environment';

interface ObservableOperator {
  (source: Observable<any>): Observable<any>;
}

const userIsValid = (user: UserGroupType | UserType): boolean => {
  return !!user.Username || typeof user.Username === 'undefined';
};

const groupIsValid = (group: GroupType): boolean => {
  return !!group.GroupName || typeof group.GroupName === 'undefined';
};

/* Interface not strictly necessary, but is helpful to understanding everything that is available */
interface AdminClassType {
  /* Used for configuration of the credentials necessary for each method */
  getCredentials(): Observable<CognitoIdentityServiceProvider>;
  me(): Observable<any>;

  /* Methods for changing user settings */
  createUser(email: string): Observable<any>;
  forceChangePassword(user: UserGroupType): Observable<any>;
  disableUser(user: UserGroupType): Observable<any>;
  enableUser(user: UserGroupType): Observable<any>;
  updateUserAttributes(user: UserGroupType): Observable<any>;

  /* Methods for dealing with groups */
  addUserToGroup(user: UserGroupType, group: GroupType): Observable<any>;
  listGroups(): Observable<GroupType[]>;
  getUsers(group: GroupType): Observable<UserGroupType[]>;
  getGroupsForUser(user: UserType | UserGroupType): Observable<GroupType[]>;
  updateGroupsForUser(user: UserGroupType): Observable<any>;
  findDuplicates(): ObservableOperator;
  handleDuplicates(): ObservableOperator;

  /* Methods for dealing with list of users */
  listUsers(): Observable<any>;
  convertToUserGroupType(group: GroupType | undefined): ObservableOperator;
  getStreamOfUsers(): ObservableOperator;
}

export class Admin implements AdminClassType {
  me = (): Observable<any> => {
    return from(Auth.currentAuthenticatedUser());
  };

  createUser = (email: string): Observable<any> => {
    return forkJoin({
      me: this.me(),
      cognito: this.getCredentials(),
    }).pipe(
      map(({ me, cognito }) => {
        return {
          cognito: cognito,
          params: {
            Username: email,
            UserPoolId: cognitoConfig.aws_user_pools_id,
            UserAttributes: [
              {
                Name: 'custom:organization_id',
                Value: me.attributes['custom:organization_id'],
              },
            ],
          },
        };
      }),
      switchMap(({ cognito, params }) =>
        CognitoMethodWrappers.createUser(cognito, params)
      )
    );
  };

  forceChangePassword = (user: UserGroupType): Observable<any> => {
    if (!userIsValid(user)) {
      return throwError('Username is missing');
    }
    return this.getCredentials().pipe(
      switchMap((cognito) =>
        CognitoMethodWrappers.forceChangePassword(cognito, user.Username!)
      )
    );
  };

  disableUser = (user: UserGroupType): Observable<any> => {
    if (userIsValid(user)) {
      const username = user.Username!;
      return this.getCredentials().pipe(
        switchMap((cognito) =>
          CognitoMethodWrappers.disableUser(cognito, username)
        )
      );
    } else {
      return throwError('Username is missing');
    }
  };

  enableUser = (user: UserGroupType): Observable<any> => {
    if (userIsValid(user)) {
      const username = user.Username!;
      return this.getCredentials().pipe(
        switchMap((cognito) =>
          CognitoMethodWrappers.enableUser(cognito, username)
        )
      );
    }

    return throwError('Username is missing');
  };

  updateUserAttributes = (user: UserGroupType): Observable<any> => {
    if (userIsValid(user)) {
      const updateUserParams: AdminUpdateUserAttributesRequest = {
        ...params,
        UserAttributes: [
          {
            Name: 'given_name',
            Value: user.FirstName,
          },
          {
            Name: 'family_name',
            Value: user.LastName,
          },
          {
            Name: 'email',
            Value: user.Email,
          },
        ],
        Username: user.Username!,
      };

      return this.getCredentials().pipe(
        switchMap((cognito) =>
          CognitoMethodWrappers.updateUserAttributes(cognito, updateUserParams)
        )
      );
    } else {
      return throwError('Username is missing');
    }
  };

  removeUserFromGroup = (
    user: UserGroupType,
    group: GroupType
  ): Observable<any> => {
    const userValid = userIsValid(user);
    const groupValid = groupIsValid(group);

    if (!(userValid && groupValid)) {
      return throwError('Username and GroupName are missing');
    } else if (!userValid) {
      return throwError('Username is missing');
    } else if (!groupValid) {
      return throwError('Groupname is missing.');
    } else {
      const username = user.Username!;
      const groupname = group.GroupName!;
      return this.getCredentials().pipe(
        switchMap((cognito) =>
          CognitoMethodWrappers.removeUserFromGroup(
            cognito,
            username,
            groupname
          )
        )
      );
    }
  };

  addUserToGroup = (user: UserGroupType, group: GroupType): Observable<any> => {
    const userValid = userIsValid(user);
    const groupValid = groupIsValid(group);

    if (!(userValid && groupValid)) {
      return throwError('Username and GroupName are missing');
    } else if (!userValid) {
      return throwError('Username is missing');
    } else if (!groupValid) {
      return throwError('Groupname is missing.');
    } else {
      const username = user.Username!;
      const groupname = group.GroupName!;
      return this.getCredentials().pipe(
        switchMap((cognito) =>
          CognitoMethodWrappers.addUserToGroup(cognito, username, groupname)
        ),
        map(() => user)
      );
    }
  };

  /* Returns the full list of users (not including group information) */
  listUsers = (): Observable<any> => {
    return this.getCredentials().pipe(
      switchMap((cognito) => CognitoMethodWrappers.listUsers(cognito)),
      this.convertToUserGroupType(undefined)
    );
  };

  /* Returns the list of groups */
  listGroups = (): Observable<GroupType[]> => {
    return this.getCredentials().pipe(
      switchMap((cognito) => from(CognitoMethodWrappers.listGroups(cognito)))
    );
  };

  /* Returns a list of users with group information added */
  getUsers = (group: GroupType) => {
    return forkJoin({
      me: this.me(),
      cognito: this.getCredentials(),
    }).pipe(
      switchMap(({ me, cognito }) => {
        if (!group?.GroupName) {
          return throwError('Group name missing');
        } else {
          return CognitoMethodWrappers.listUsersInGroup(
            cognito,
            group.GroupName
          ).pipe(
            this.convertToUserGroupType(group),
            map((usersInGroup) =>
              usersInGroup.filter(
                (user) =>
                  user.OrganizationID ===
                  me.attributes['custom:organization_id']
              )
            )
          );
        }
      })
    );
  };

  getUserByID = (userID: string): Observable<UserType> => {
    const extraParams: Partial<ListUsersRequest> = {
      Filter: `username = "${userID}"`,
    };
    return this.getCredentials().pipe(
      switchMap((cognito) =>
        CognitoMethodWrappers.listUsers(cognito, extraParams)
      ),
      map((users) => users[0])
    );
  };

  updateGroupsForUser = (user: UserGroupType): Observable<any> => {
    if (!userIsValid(user)) {
      return throwError('Username is missing');
    } else {
      return of([]);
    }
  };

  getGroupsForUser = (
    user: UserType | UserGroupType
  ): Observable<GroupType[]> => {
    if (!userIsValid(user)) {
      return throwError('Username is missing');
    }
    return this.getCredentials().pipe(
      switchMap((cognito) =>
        CognitoMethodWrappers.listGroupsForUser(cognito, user.Username!)
      )
    );
  };

  /* Groups users by their email to detect users in multiple groups */
  findDuplicates = () => (source: Observable<UserGroupType>) => {
    return source.pipe(
      groupBy((user) => user.Email),
      mergeMap((groups) => groups.pipe(toArray()))
    );
  };

  /* If a user is in more than one group, only show the one with the highest precedence */
  /* This is most likely a temporary fix until solitary group membership is enforced */
  handleDuplicates =
    () =>
    (source: Observable<UserGroupType[]>): Observable<UserGroupType[]> => {
      /* From two users, choose the one with the higher group precedence */
      const compareGroupPrecedence = (
        prevUser: UserGroupType,
        currUser: UserGroupType
      ): UserGroupType => {
        return prevUser.Group!.Precedence! > currUser.Group!.Precedence!
          ? prevUser
          : currUser;
      };

      /* Find the highest precedence level group in the entire list (of groups that a single user is in) */
      const highestPrecedenceGroup = (
        users: UserGroupType[]
      ): UserGroupType => {
        return users.reduce((acc: UserGroupType, user: UserGroupType) => {
          if (user.Group!.Precedence && acc.Group!.Precedence) {
            acc = compareGroupPrecedence(user, acc) ?? acc;
          }
          return acc;
        }, users[0]);
      };

      return source.pipe(
        map((users) =>
          users.length > 1 ? [highestPrecedenceGroup(users)] : users
        ),
        flatten$()
      );
    };

  getStreamOfUsers =
    () =>
    (source: Observable<GroupType[]>): Observable<UserGroupType> => {
      return source.pipe(
        concatAll(),
        mergeMap((group) => this.getUsers(group)),
        concatAll()
      );
    };

  getCredentials(): Observable<CognitoIdentityServiceProvider> {
    return from(Auth.currentCredentials()).pipe(
      switchMap((credentials) =>
        of(
          new CognitoIdentityServiceProvider({
            ...credentials,
            ...params,
            ...awsConfig,
          })
        )
      )
    );
  }

  convertToUserGroupType =
    (group: GroupType | undefined) =>
    (source: Observable<UserType[]>): Observable<any[]> => {
      /* Returns cleaned user object */
      const userWithoutUnneccessaryProperties = (user: UserType) => {
        /* Remove properties that we do not use */
        const { Attributes, UserLastModifiedDate, ...otherProperties } = user;
        /* Make sure that the group property is added to object even if undefined */
        return { ...otherProperties };
      };

      /* Mapping of AttributeType names to more readable names */
      const keyMappings: Record<string, string> = {
        given_name: 'FirstName',
        family_name: 'LastName',
        email: 'Email',
        'custom:organization_id': 'OrganizationID',
      };

      /* Parse AttributeType to single object */
      const parseAttributeType = (
        attribute: AttributeType
      ): Record<string, string> | void => {
        const obj: Record<string, string> = {};
        const key = keyMappings[attribute.Name];
        if (key) {
          obj[key] = attribute.Value ?? '';
          return obj;
        }
      };

      /* Parses all attributes from original object for our new user object */
      const parseAllAttributes = (
        attributes: AttributeType[]
      ): Record<string, string> => {
        let parsedAttributes: Record<string, string> = {};
        attributes.forEach((attribute: AttributeType) => {
          const attr = parseAttributeType(attribute);
          if (attr !== undefined) {
            parsedAttributes = { ...parsedAttributes, ...attr };
          }
        });
        return parsedAttributes;
      };

      /* Transforms users from UserType[] to UserGroupType[] */
      const transformUserList = (users: UserType[]): UserGroupType[] => {
        return users.reduce((acc: any[], user: UserType) => {
          let updatedUser = userWithoutUnneccessaryProperties(
            user
          ) as UserGroupType;

          updatedUser.Group = group;

          if (user.Attributes) {
            updatedUser = {
              ...updatedUser,
              ...parseAllAttributes(user.Attributes),
            };
          }

          acc.push(updatedUser);
          return acc;
        }, [] as UserGroupType[]);
      };

      /* RxJs Operator */
      return source.pipe(map((users) => transformUserList(users)));
    };
}

/**
 * This object contains the actual Cognito methods that are used. They are encapsulated
 * in an object to make it clear that these are only used in the background and won't
 * get of much use directly without further processing that occurs in the Admin class.
 * */
const CognitoMethodWrappers = {
  listUsers: (
    cognito: CognitoIdentityServiceProvider,
    extraParams?: Partial<ListUsersRequest>
  ): Observable<UserType[]> => {
    return from(
      cognito
        .listUsers({
          ...params,
          ...extraParams,
          AttributesToGet: userAttributes,
        })
        .promise()
    ).pipe(
      map((response) => response.$response.data),
      filter((response): response is ListUsersResponse =>
        isNotUndefined(response)
      ),
      map((response) => response.Users),
      catchNotAuthorized(RETURN_EMPTY)
    );
  },

  listGroups: (
    cognito: CognitoIdentityServiceProvider
  ): Observable<GroupType[]> => {
    return from(cognito.listGroups(params).promise()).pipe(
      map((response) => response.$response.data),
      filter((response): response is ListGroupsResponse =>
        isNotUndefined(response)
      ),
      map((response) => response.Groups),
      catchNotAuthorized(RETURN_EMPTY)
    );
  },

  listUsersInGroup: (
    cognito: CognitoIdentityServiceProvider,
    groupName: string
  ): Observable<UserType[]> => {
    return from(
      cognito.listUsersInGroup({ ...params, GroupName: groupName }).promise()
    ).pipe(
      map((response) => response.$response.data),
      filter((response): response is ListUsersInGroupResponse => {
        return isNotUndefined(response);
      }),
      map((response) => response.Users),
      catchNotAuthorized(RETURN_EMPTY)
    );
  },

  listGroupsForUser: (
    cognito: CognitoIdentityServiceProvider,
    username: string
  ): Observable<GroupType[]> => {
    return from(
      cognito
        .adminListGroupsForUser({ ...params, Username: username })
        .promise()
    ).pipe(
      map((response) => response.$response.data),
      filter((response): response is AdminListGroupsForUserResponse =>
        isNotUndefined(response)
      ),
      map((groups) => groups.Groups),
      filter((groups): groups is GroupType[] => isNotUndefined(groups)),
      catchNotAuthorized()
    );
  },

  updateUserAttributes: (
    cognito: CognitoIdentityServiceProvider,
    params: AdminUpdateUserAttributesRequest
  ): Observable<AdminUpdateUserAttributesResponse> => {
    return from(
      cognito.adminUpdateUserAttributes({ ...params }).promise()
    ).pipe(
      map((response: AdminUpdateUserAttributesResponse) => response),
      filter((response): response is AdminUpdateUserAttributesResponse =>
        isNotUndefined(response)
      ),
      catchNotAuthorized()
    );
  },

  forceChangePassword: (
    cognito: CognitoIdentityServiceProvider,
    username: string
  ): Observable<AdminResetUserPasswordResponse> => {
    return from(
      cognito
        .adminResetUserPassword({ ...params, Username: username })
        .promise()
    ).pipe(
      map((response) => response.$response.data),
      filter((response): response is AdminResetUserPasswordResponse =>
        isNotUndefined(response)
      ),
      catchNotAuthorized()
    );
  },

  enableUser: (
    cognito: CognitoIdentityServiceProvider,
    username: string
  ): Observable<AdminEnableUserResponse> => {
    return from(
      cognito.adminEnableUser({ ...params, Username: username }).promise()
    ).pipe(
      map((response) => response.$response.data),
      filter((response): response is AdminEnableUserResponse =>
        isNotUndefined(response)
      ),
      catchNotAuthorized()
    );
  },

  disableUser: (
    cognito: CognitoIdentityServiceProvider,
    username: string
  ): Observable<AdminDisableUserResponse> => {
    return from(
      cognito.adminDisableUser({ ...params, Username: username }).promise()
    ).pipe(
      map((response) => response.$response.data),
      filter((response): response is AdminDisableUserResponse =>
        isNotUndefined(response)
      ),
      catchNotAuthorized()
    );
  },

  createUser: (
    cognito: CognitoIdentityServiceProvider,
    params: AdminCreateUserRequest
  ): Observable<AdminCreateUserResponse> => {
    return from(cognito.adminCreateUser(params).promise()).pipe(
      map((response) => response.$response.data),
      filter((response): response is AdminCreateUserResponse =>
        isNotUndefined(response)
      ),
      catchNotAuthorized()
    );
  },

  removeUserFromGroup: (
    cognito: CognitoIdentityServiceProvider,
    username: string,
    groupname: string
  ): Observable<any> => {
    const removeUserParams = {
      ...params,
      Username: username,
      GroupName: groupname,
    };
    return from(
      cognito.adminRemoveUserFromGroup(removeUserParams).promise()
    ).pipe(
      map((response) => response.$response.data),
      catchNotAuthorized()
    );
  },

  addUserToGroup: (
    cognito: CognitoIdentityServiceProvider,
    username: string,
    groupname: string
  ): Observable<any> => {
    const addUserParams = {
      ...params,
      Username: username,
      GroupName: groupname,
    };
    return from(cognito.adminAddUserToGroup(addUserParams).promise()).pipe(
      map((response) => {
        return response.$response.data;
      }),
      catchNotAuthorized()
    );
  },
};
