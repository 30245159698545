export enum Colors {
  Primary = 1,
  PrimaryDark = 2,
  Secondary = 3,
  SecondaryDark = 4,
  SecondaryAlt = 5,
}

export const ColorValues = {
  1: '#3F86CC',
  2: '#0C2F87',
  3: '#EDEDEC',
  4: '#505050',
  5: 'gray',
};

export enum Notifications {
  AuthoredQuestionUpdated = 1,
  AuthoredQuestionDeleted = 2,
  AuthoredAssessmentUpdated = 3,
  AuthoredAssessmentDeleted = 4,
  AuthoredAssessmentInvalidated = 5,
  AuthoredInviteUpdated = 6,
  AuthoredInviteDeleted = 7,
  AuthoredInviteInvalidated = 8,
  CandidateBeganAssessment = 9,
  CandidateCompletedAssessment = 10,
  ReviewAssignedToYou = 11,
  ReviewDueIn24hrs = 12,
  ReviewNowPastDue = 13,
  ReviewHasBeenCompleted = 14,
  AuthoredQuestionNewVersion = 15,
  AuthoredAssessmentNewVersion = 16,
  AuthoredQuestionUnarchived = 17,
  AuthoredAssessmentUnarchived = 18,
  AuthoredInviteUnarchived = 19,
  ReviewArchived = 20,
  ReviewUnarchived = 21,
}

export const MaxAnswerLen: number = 10000;

export enum ReviewStatuses {
  UNASSIGNED = 'Review Unassigned',
  ASSIGNED = 'Review Assigned',
  PENDING = 'Evaluation Pending',
  SUBMITTED = 'Evaluation Submitted',
}
