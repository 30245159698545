import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Cacheable, CacheBuster } from 'ts-cacheable';
import { apiUrl } from 'src/environments/environment';
import {
  Assessment,
  AssessmentType,
  QueryOptions,
} from 'src/app/shared/models/models.index';
import { HttpErrorService } from 'src/app/shared/services/http-error.service';
import {
  UserActivityService,
  ResourceType,
} from 'src/app/shared/services/user-activity/user-activity.service';

const cacheBuster$ = new Subject<void>();
const HOUR_TIME = 3600000;
@Injectable({
  providedIn: 'root',
})
export class AssessmentsBuilderService {
  constructor(
    private http: HttpClient,
    private httpError: HttpErrorService,
    private userActivity: UserActivityService
  ) {}

  @Cacheable({
    cacheBusterObserver: cacheBuster$,
    maxAge: HOUR_TIME,
    slidingExpiration: true,
    maxCacheCount: 2,
  })
  getAssessment(
    id: number,
    queryOptions?: Pick<QueryOptions, 'includeDeleted'>
  ): Observable<Assessment> {
    return this.userActivity.readResource(ResourceType.ASSESSMENT, {
      resourceId: id,
      queryOptions,
    });
  }

  @Cacheable({
    cacheBusterObserver: cacheBuster$,
    maxAge: HOUR_TIME,
    slidingExpiration: true,
  })
  getAssessments(
    queryOptions?: Pick<QueryOptions, 'includeInvalid' | 'includeDeleted'>
  ): Observable<Assessment[]> {
    return this.userActivity.readResource(ResourceType.ASSESSMENT, {
      queryOptions,
    });
  }

  @Cacheable({
    cacheBusterObserver: cacheBuster$,
    maxAge: HOUR_TIME,
    slidingExpiration: true,
  })
  getAssessmentsByQuestionID(
    queryOptions?: Pick<QueryOptions, 'includeInvalid' | 'questionID'>
  ): Observable<Assessment[]> {
    return this.userActivity.readResource(ResourceType.ASSESSMENT, {
      queryOptions,
    });
  }

  @Cacheable({
    cacheBusterObserver: cacheBuster$,
    maxAge: HOUR_TIME,
    slidingExpiration: true,
  })
  getAssessmentsResponsesByQuestionID(
    queryOptions?: Pick<
      QueryOptions,
      'includeResponses' | 'includeLinkedResponses' | 'questionID'
    >
  ): Observable<Assessment[]> {
    return this.userActivity.readResource(ResourceType.ASSESSMENT, {
      queryOptions,
    });
  }

  @Cacheable({
    cacheBusterObserver: cacheBuster$,
    maxAge: HOUR_TIME,
    slidingExpiration: true,
  })
  getAssessmentsByRotationID(
    queryOptions?: Pick<QueryOptions, 'includeInvalid' | 'rotationID'>
  ): Observable<Assessment[]> {
    return this.userActivity.readResource(ResourceType.ASSESSMENT, {
      queryOptions,
    });
  }

  @CacheBuster({
    cacheBusterNotifier: cacheBuster$,
  })
  updateAssessment(id: number, assessment: Assessment): Observable<any> {
    return this.userActivity.updateResource(
      id,
      assessment,
      ResourceType.ASSESSMENT
    );
  }

  @CacheBuster({
    cacheBusterNotifier: cacheBuster$,
  })
  undeleteAssessment(id: number, assessment: Assessment): Observable<any> {
    return this.userActivity.updateResource(
      id,
      assessment,
      ResourceType.ASSESSMENT
    );
  }

  @CacheBuster({
    cacheBusterNotifier: cacheBuster$,
  })
  saveAssessment(assessment: Assessment): Observable<any> {
    return this.userActivity.createResource(
      assessment,
      ResourceType.ASSESSMENT
    );
  }

  @CacheBuster({
    cacheBusterNotifier: cacheBuster$,
  })
  deleteAssessment(id: number): Observable<any> {
    return this.userActivity.deleteResource(id, ResourceType.ASSESSMENT);
  }
}
