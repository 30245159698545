import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { ICredentials } from '@aws-amplify/core';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { CognitoService } from 'src/app/shared/services/cognito/cognito.service';
import * as sigv4 from './sigv4/core';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private cognitoService: CognitoService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (request.headers.has('X-Skip-Request-Signing')) {
      return next.handle(request);
    } else {
      return this.cognitoService.getUserCredentials().pipe(
        mergeMap((creds: ICredentials) => {
          return next.handle(sigv4.sign(request, creds));
        })
      );
    }
  }
}
